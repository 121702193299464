.sidebarItem {
  @apply rounded bg-white px-6 py-2 shadow-md transition-colors hover:bg-gray-300;
}

.sidebarItemActive {
  @apply rounded bg-gray-300 px-6 py-2 shadow-md transition-colors;
}

.tableItem {
  @apply min-w-[25%] overflow-auto p-2 flex justify-center;
}
